.page-not-found-main {
    color: rgb(40, 155, 65);
  }
  
  .page-not-found-main img {
    display: block;
    max-width: 150px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    align-self: center;
  }

  .page-not-found-main {
      margin-left: auto;
      margin-right: auto;
      padding-top: 125px;
      align-content: center;
      text-align: center;
  }